/*-----------global--------------*/
.interactivCcnt #ccntBody {
    width: 100% !important;
    aspect-ratio: 29.7/21 !important;
    overflow: visible;
    white-space: nowrap;
}

/*--------------------very top--------------------------*/
.interactivCcnt .align-left {
    text-align: left !important;
}

.interactivCcnt .align-center {
    text-align: center !important;
}

.interactivCcnt .align-right {
    text-align: right !important;
}

/*-----------ccnt main table--------------*/
.interactivCcnt .mainTable {
    text-align: center;
}

.interactivCcnt .firsttableCell {
    width: 2vw;
    max-height: 1.3vw;
    height: 1.3vw;
    min-height: 1.3vw;
}

.interactivCcnt .firsttableCell p {
    font-size: 11px;
}

.interactivCcnt .tableTextLetter {
    font-size: 10px;
    color: black;
    border-bottom: 1px dashed rgb(177, 177, 177);
    padding-top: 1px;
}

.interactivCcnt .tableTextLetterNoDot {
    font-size: 10px;
    color: black;
    padding: 2px;
    margin-top: 2px;
}

.interactivCcnt .tableTextNumber {
    font-size: 10px;
    color: black;
    padding-top: 5px;
}

.interactivCcnt .ccnt-table-error {
    background-color: rgb(176, 33, 33) !important;
    cursor: pointer;
}

.interactivCcnt .ccnt-table-warning {
    background-color: rgb(255, 211, 172) !important;
    cursor: pointer;
}

.interactivCcnt .ccnt-table-info {
    background-color: rgb(255, 211, 172) !important;
    cursor: pointer;
}

.interactivCcnt .ccnt-table-ccnt-warning {
    background-color: rgb(255, 118, 118) !important;
    cursor: pointer;
}

.interactivCcnt .ccnt-table-multiple-errors {
    background-color: rgb(255, 211, 172) !important;
    cursor: pointer;
}

.interactivCcnt.v2 .ccnt-table-error {
    background-color: rgb(232, 29, 29) !important;
    cursor: pointer;
}

.interactivCcnt.v2 .ccnt-table-warning {
    background-color: rgb(255, 211, 172) !important;
    cursor: pointer;
}

.interactivCcnt.v2 .ccnt-table-info {
    background-color: rgb(128, 249, 255) !important;
    cursor: pointer;
}

.interactivCcnt.v2 .ccnt-table-ccnt-warning {
    background-color: rgb(255, 118, 118) !important;
    cursor: pointer;
}

.interactivCcnt.v2 .ccnt-table-multiple-errors {
    background-color: rgb(255, 211, 172) !important;
    cursor: pointer;
}


.interactivCcnt .ccntDefaultErrorMessage {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
}

.interactivCcnt .ccntDefaultErrorMessageHidden {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
}

.interactivCcnt .tableCell {
    border-left: 1px solid rgb(177, 177, 177);
    border-right: 1px solid rgb(177, 177, 177);
    max-width: 35px;
    width: 35px;
    min-width: 35px;
    max-width: 1.5vw;
    aspect-ratio: 1;
    text-align: center;
}

.interactivCcnt .tableCellEven {
    border-left: 1px solid rgb(177, 177, 177);
    background-color: #ccccff;
    border-right: 1px solid rgb(177, 177, 177);
    max-width: 35px;
    width: 35px;
    min-width: 35px;
    aspect-ratio: 1;
    text-align: center;
}

.interactivCcnt .tableCellBlack {
    border-left: 1px solid rgb(177, 177, 177);
    border-right: 1px solid rgb(177, 177, 177);
    background-color: black;
    max-width: 35px;
    width: 35px;
    min-width: 35px;
    aspect-ratio: 1;
}

.interactivCcnt .tableCellGray {
    border-left: 1px solid rgb(177, 177, 177);
    border-right: 1px solid rgb(177, 177, 177);
    background-color: grey;
    max-width: 35px;
    width: 35px;
    min-width: 35px;
    aspect-ratio: 1;
}

.interactivCcnt .tableRow td {
    max-height: 3vw;
    height: 3vw;
    min-height: 3vw;
}

.interactivCcnt .tableRow td p {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    padding-right: 0vw;
    padding-left: 0vw;
}

.interactivCcnt .topTable {
    width: 100%;
    margin-bottom: 10px;
    Border-radius: on table;
    border-collapse: collapse !important;
    border-radius: var(--border-radius);
    border-style: hidden;
    box-shadow: 0 0 0 1px rgb(177, 177, 177);
    table-layout: fixed !important;
}

.interactivCcnt .topTable h1 {
    font-size: 30px;
}

.interactivCcnt .topTable h3 {
    font-size: 20px;
    margin: 0;
}

.interactivCcnt .topTable h4 {
    font-size: 10px;
    margin: 0;
}

.interactivCcnt .topTable td {
    border: 1px solid rgb(177, 177, 177);
    padding: 0px;
    margin: 0px;
    text-align: center;
}


.interactivCcnt .standartCellCenter {
    border-left: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .standartCellCenter h4 {
    font-weight: bold;
}

.interactivCcnt .standartCell h1 {
    font-weight: bold;
    text-align: center;
    padding: 0px;
    margin: 0px;
}

.interactivCcnt .standartCell h3 {
    font-weight: bold;
    text-align: center;
}

.interactivCcnt .standartCell h4 {
    font-weight: bold;
}

.interactivCcnt .standartCell p {
    color: black;
}


.interactivCcnt .arrowPngHours {
    position: absolute;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
}

.interactivCcnt .arrowPngRest {
    position: absolute;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
}

.interactivCcnt .arrowPngWorkPerWeek {
    position: absolute;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
}

.interactivCcnt .hoursInputCell {
    border-left: 1px solid rgb(177, 177, 177);
    width: 50px;
    text-align: center;
    font-size: 14px;
}

.interactivCcnt .middleRow {
    border-bottom: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .topTableTitleCell {
    vertical-align: top;
    font-size: 14px;
}

.interactivCcnt .cellTitle {
    margin-top: 0px;
    font-weight: bold;
    font-size: 14px;
    color: black;
    background-color: rgb(179, 177, 177);
}

.interactivCcnt .cellInformationText {
    text-align: left;
    margin-top: 20px;
    margin-left: 5px;
}

.interactivCcnt .topRightTable {
    border: 1px solid rgb(177, 177, 177);
    width: 600px;
    margin-left: -3px;
    margin-bottom: -4px;
}

.interactivCcnt .verticalAllignBottom {
    vertical-align: bottom;
    width: 2.0vw;
    max-width: 2.0vw;
    height: 9vh;
    min-height: 9vh;
    max-height: 9vh;
}

.interactivCcnt .topRightCellSignature {
    vertical-align: bottom;
    text-align: center;
    border-left: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .rotatedTopTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    position: relative;
    font-weight: bold;
    font-size: 12px !important;
    color: black;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.interactivCcnt .rotatedTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-weight: bold;
    font-size: 12px !important;
    color: black;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.interactivCcnt .rotatedsubTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-weight: normal;
    font-size: 9px !important;
    color: black;
    padding: 0px;
    margin: 0px;
}


.interactivCcnt .tableRight {
    color: black;
    border: 1px solid rgb(177, 177, 177);
    text-align: center;
    margin-left: -3px;
}

.interactivCcnt .specialRow {
    border-bottom: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .specialCell {
    border-left: 1px solid rgb(177, 177, 177);
    background-color: rgb(206, 206, 206);
}

.interactivCcnt .specialCellBlack {
    border-left: 1px solid rgb(177, 177, 177);
    background-color: black;
    width: 40px;
}

.interactivCcnt .specialCellGray {
    border-left: 1px solid rgb(177, 177, 177);
    background-color: grey;
}

.interactivCcnt .specialText {
    color: black;
    font-size: 10px;
}

.interactivCcnt .specialTextbig {
    color: black;
    font-size: 10px;
}

.interactivCcnt .rightTableRow {
    border-bottom: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .rightTableRow p {
    color: black;
    vertical-align: text-bottom;
    padding: 0px;
    margin: 0px;
}

.interactivCcnt .rightTableRow td {
    border-left: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .rightStandartCell {
    border-left: 1px solid rgb(177, 177, 177);
    vertical-align: middle;
    font-size: 10.5px;
}

.interactivCcnt .rightSignCell {
    border-left: 1px solid rgb(177, 177, 177);
    width: 3.3vw;
}

.interactivCcnt .sumupTable {
    margin-left: 1094px;
    margin-top: -3px;
    width: 590px;
    max-width: 590px;
    min-width: 590px;
    text-align: center;
}

.interactivCcnt .sumupTable .specialCellBlack {
    width: 50px;
    border-left: 1px solid rgb(177, 177, 177);
    border-bottom: 1px solid rgb(177, 177, 177);
}

.interactivCcnt .sumupTable .rightStandartCell {
    border-left: 1px solid rgb(177, 177, 177);
    border-bottom: 1px solid rgb(177, 177, 177);
}


.interactivCcnt .totalTitleParagraph {
    font-size: 9pt;
    font-weight: bold;
    padding-top: 2px;
    padding-right: 3px;
    padding-bottom: 0px;
    vertical-align: bottom !important;
}

.interactivCcnt .emptycolumnTable {
    border: None !important;
    text-align: right !important;
}

.interactivCcnt .bufferColumn {
    border: None !important;
}

.interactivCcnt .bottomRow {
    border-right: 1px solid rgb(177, 177, 177);
    height: 27px;
}

.interactivCcnt .bottomRow p {
    font-size: 8pt;
}

/*-----------descr--------------*/

.interactivCcnt .totalTable {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 50px;
}

.interactivCcnt .totalTable p {
    color: black;
}

.interactivCcnt .totalDataCell {
    border: 0.5px dotted rgb(177, 177, 177);
    font-size: smaller;
    text-align: center;
    height: 20px;
    width: 80px;
}

.interactivCcnt .totalFineCells {
    font-size: x-small;
    text-align: center;
}

.interactivCcnt .totalLegendCells {
    font-size: x-small;
    text-align: left;
}

.interactivCcnt .totalSeparatorCell {
    width: 25px;
    height: 5px;
}

.interactivCcnt .totalThickCells {
    font-size: small;
    font-weight: bold;
    padding-right: 5px;
}

.interactivCcnt .legendTable {
    margin-left: 40px;
    width: 50vw;
}

.interactivCcnt .legendLetter {
    color: black;
    font-size: small;
}

.interactivCcnt .cellLegendLetter {
    border: 1px solid rgb(177, 177, 177);
    width: 30px;
    text-align: center;
}

.interactivCcnt .legendDescription {
    color: black;
    font-size: 12px;
    min-width: 2vw;
}

.interactivCcnt .cellLegendDescription {
    padding-left: 5px;
}

.interactivCcnt .legendSubDescription {
    color: black;
    font-style: italic;
    font-size: 10px;
}


.interactivCcnt .signatureTable {
    width: 100%;
    margin-left: 50px;
    margin-top: 20px;
}

.interactivCcnt .signatreRow {
    vertical-align: top;
}

.interactivCcnt .signatureCell {
    padding-top: 10px;
}

.interactivCcnt .signatureCell p {
    font-style: italic;
    font-size: x-small;
    color: black;
}



/* ####################################################################################################### */
/* ###################################  CcntPrintPage  ################################################### */

.ccntPrintBody {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.74);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    overflow-y: auto;
    overflow-x: auto;

}

.cctPrintBody {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.74);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    overflow-y: auto;
    overflow-x: auto;

}

.ccntPrintButtons {
    position: fixed;
    right: 0;
    bottom: 20px;
    z-index: 1001;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ccntPaper {
    background-color: white;
    width: 1627.4px;
    height: 1150px;
    /* display: inline-block;
    justify-content: center;
    align-items: center; */
    /* padding-left: 75px ; */
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    margin: auto;
}

.ccntPaperGroup {
    border: 1px solid black;
    padding: 50px;
}

@media print {
    .ccntPaperGroup {
        page-break-after: right !important;
        border: none !important;
        padding: 0px !important;
    }
}

.ccntPaperMainBody {
    padding-top: 30px;
    /* width: 1485px ; */
    /* height: 1050px ; */
}

/* -------------- Header -----------------*/
.header {
    font-size: 10pt;
    width: 100%;
    /* padding-right: 70px; */
    display: flex;
}

.headerLeft {
    min-width: 33%;
    max-width: 34%;
    text-align: left;
}

.headerCenter {
    min-width: 33%;
    max-width: 33%;
    text-align: center;
}

.headerRight {
    min-width: 33%;
    max-width: 33%;
    text-align: right;
}

/*-----------global--------------*/
.ccntPaper #ccntBody {
    width: 100% !important;
    overflow: visible;
    white-space: nowrap;
}

/*--------------------very top--------------------------*/
.ccntPaper .align-left {
    text-align: left !important;
}

.ccntPaper .align-center {
    text-align: center !important;
}

.ccntPaper .align-right {
    text-align: right !important;
}

/*-----------ccnt main table--------------*/
.ccntPaper .mainTable {
    text-align: center;
}

.ccntPaper .firsttableCell {
    max-height: 20px;
    height: 20px;
    min-height: 20px;
}

.ccntPaper .firsttableCell p {
    font-size: 7pt;
}

.ccntPaper .tableTextLetter {
    font-size: 6.5pt;
    color: black;
    border-bottom: 1px dashed black;
    padding-top: 1px;
    height: 22.5px;
}

.ccntPaper .tableTextLetterNoDot {
    font-size: 6.5pt;
    color: black;
    padding: 2px;
    margin-top: 2px;
    height: 22.5px;
}

.ccntPaper .tableTextNumber {
    font-size: 6.5pt;
    color: black;
    padding-top: 5px;
    height: 22.5px;
}

.ccntPaper .ccnt-table-ccnt-warning {
    background-color: rgb(255, 118, 118) !important;
}

.ccntPaper .ccntDefaultErrorMessage {
    font-size: 8pt;
    color: red !important;
    max-width: 200px;
    background-color: yellow !important;
    text-align: left;
}

.ccntPaper .ccntDefaultErrorMessageHidden {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
}

.ccntPaper .tableCell {
    max-width: 29px;
    width: 29px;
    min-width: 29px;
    text-align: center;
}

.ccntPaper .tableCellEven {
    max-width: 29px;
    background-color: #c6c6ff;
    width: 29px;
    min-width: 29px;
    text-align: center;
}

.ccntPaper .tableCellBlack {
    background-color: black !important;
    max-width: 29px;
    width: 29px;
    min-width: 29px;
}

.ccntPaper .tableCellGray {
    background-color: grey !important;
    max-width: 29px;
    width: 29px;
    min-width: 29px;
}


.ccntPaper .tableRow td p {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    padding-right: 0vw;
    padding-left: 0vw;
}


.ccntPaper .tableRow td {
    max-height: 49px;
    height: 49px;
    min-height: 49px;
}

.ccntPaper .topTable {
    width: 100%;
    margin-bottom: 10px;
}

.ccntPaper .topTable h1 {
    font-size: 20pt;
}

.ccntPaper .topTable h3 {
    font-size: 11pt;
    margin: 0;
}

.ccntPaper .topTable h4 {
    font-size: 8pt;
    margin: 0;
}

.ccntPaper .topTable td {
    border: 1px solid black;
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.ccntPaper .standartCellCenter h4 {
    font-weight: bold;
    margin-bottom: -13px;
    padding-left: 3px;
}

.ccntPaper .standartCell h1 {
    font-weight: bold;
    text-align: center;
    padding: 0px;
    margin: 0px;
}

.ccntPaper .standartCell h3 {
    font-weight: bold;
    text-align: center;
}

.ccntPaper .standartCell h4 {
    font-weight: bold;
}

.ccntPaper .standartCell p {
    color: black;
}


.ccntPaper .hoursInputCell {
    text-align: center;
    font-size: 10pt;
}

.ccntPaper .topTableTitleCell {
    vertical-align: top;
    font-size: 10pt;
}

.ccntPaper .cellTitle {
    margin-top: 0px;
    font-weight: bold;
    font-size: 9pt;
    text-align: left;
    padding-left: 3px;
    color: black;
    background-color: rgb(211, 211, 211);
}

.ccntPaper .cellInformationText {
    text-align: left;
    margin-top: 20px;
    margin-left: 5px;
}

.ccntPaper .topRightTable {
    border: 1px solid black;
    width: 600px;
    margin-left: -3px;
    margin-bottom: -4px;
}

.ccntPaper .verticalAllignBottom {
    vertical-align: bottom;
    width: 35px;
    max-width: 35px;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
}

.ccntPaper .topRightCellSignature {
    vertical-align: bottom;
    text-align: center;
    border-left: 1px solid black;
}

.ccntPaper .rotatedTopTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    position: relative;
    font-weight: bold;
    font-size: 12px;
    color: black;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.ccntPaper .rotatedTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-weight: bold;
    font-size: 12px;
    color: black;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.ccntPaper .rotatedsubTitle {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-size: 9px;
    font-weight: normal;
    color: black;
    padding: 0px;
    margin: 0px;
}


.ccntPaper .tableRight {
    color: black;
    text-align: center;
    margin-left: -3px;
}

.ccntPaper .specialRow {
    border-bottom: 1px solid black;
}

.ccntPaper .specialCell {
    background-color: rgb(211, 211, 211);
}

.ccntPaper .specialCellBlack {
    background-color: black;
    width: 40px;
}

.ccntPaper .specialCellGray {
    background-color: rgb(168, 168, 168);
}

.ccntPaper .specialText {
    color: black;
    font-size: 0.6em;
    margin-top: -25px;
}


.ccntPaper .arrowPngHours {
    max-width: 90px;
    position: relative;
    padding: 0px;
    margin-right: -10px;
    margin-left: 15px;
}

.ccntPaper .arrowPngRest {
    max-width: 60px;
    position: relative;
    margin-right: -10px;
    margin-left: 0px;
}

.ccntPaper .arrowPngWorkPerWeek {
    max-width: 50px;
    position: relative;
    margin-top: -20px;
    margin-right: 10px;
    margin-left: 62px;
}


.ccntPaper .specialTextbig {
    color: black;
    font-size: 0.6em;
}

.ccntPaper .rightTableRow {
    border-bottom: 1px solid black;
}

.ccntPaper .rightTableRow p {
    color: black;
    vertical-align: text-bottom;
    padding: 0px;
    margin: 0px;
}

.ccntPaper .rightStandartCell {
    font-size: 7pt;
    vertical-align: middle;
}

.ccntPaper .rightStandartCell p {
    padding-bottom: 0 !important;
}

.ccntPaper .rightSignCell {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
}

.rightSignCell {
    position: relative;
}

.rightSignCell svg {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
}

.rightSignCell.odd svg {
    transform: translate(-50%, -50%) scale(.9);
}

.ccntPaper .sumupTable {
    margin-top: -3px;
    width: 500px;
    max-width: 500px;
    min-width: 500px;
    text-align: center;
}

.ccntPaper .sumupTable .specialCellBlack {
    width: 40px;
}

.ccntPaper .emptycolumnTable {
    border: None !important;
    text-align: right !important;
}

.ccntPaper .totalTitleParagraph {
    font-size: 9pt;
    font-weight: bold;
    padding-top: 2px;
    padding-right: 3px;
    padding-bottom: 0px;
    vertical-align: bottom !important;
}

.ccntPaper .bufferColumn {
    border: None !important;
}

.ccntPaper .bottomRow {
    height: 27px;
}

/*-----------descr--------------*/

.ccntPaper .totalTable {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.ccntPaper .totalTable p {
    color: black;
}

.ccntPaper .totalDataCell {
    border: 0.5px dotted black;
    font-size: smaller;
    text-align: center;
    height: 20px;
    width: 50px;
}

.ccntPaper .totalFineCells {
    font-size: x-small;
    text-align: center;
}

.ccntPaper .totalLegendCells {
    font-size: x-small;
    text-align: left;
}

.ccntPaper .totalSeparatorCell {
    width: 25px;
    height: 5px;
}

.ccntPaper .totalThickCells {
    font-size: small;
    font-weight: bold;
    padding-right: 5px;
}

.ccntPaper .legendTable {
    margin-left: 30px;
    width: 800px;
}

.ccntPaper .legendLetter {
    color: black;
    font-size: 9pt;
    padding-top: 2px;
}

.ccntPaper .cellLegendLetter {
    border: 1px solid black;
    width: 35px;
    min-width: 35px;
    height: 20px;
    text-align: center;
}

.ccntPaper .legendDescription {
    color: black;
    font-size: 9pt;
    padding-top: 2px;
    min-width: 20px;
}

.ccntPaper .cellLegendDescription {
    padding-left: 5px;
}

.ccntPaper .legendSubDescription {
    color: black;
    font-style: italic;
    font-size: 7pt;
}


.ccntPaper .signatureTable {
    width: 850px;
    margin-left: 50px;
    margin-top: 20px;
}

.ccntPaper .signatreRow {
    vertical-align: top;
}

.ccntPaper .signatureCell {
    padding-top: 10px;
}

.ccntPaper .signatureCell p {
    font-style: italic;
    font-size: x-small;
    color: black;
}

.signatureCell>.border-cell {
    width: 280px;
    min-height: 30px;
    height: 30px;
    max-height: 30px;
    border: 1px solid black;
    position: relative;
}


.signatureCell .pencil-signature {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
}

.signatureCell .pencil-signature svg {
    width: 100px;
    height: 100px;
}

.signatureCell fieldset {
    border: 1px solid grey;
    position: relative;
    padding: 0px 10px;
    border-radius: 1px;
    height: 45px;
    width: 290px;
}

.signatureCell fieldset legend {
    font-size: 10px;
    width: auto;
}

.ccnt-error-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(-10%, 10%);
    border-radius: 100%;
    border: 1px solid black;
    font-size: 65%;
    width: 16px;
    height: 16px;
    line-height: 14px;
    background-color: #0000008e;
    color: white
}

.ccntPaper .ccnt-error-badge {
    display: none;
}

.ccnt-balance-filter {
    transition: width 0.1s ease-in-out, opacity 0.05s ease-in-out, border 0.1s;
    /* transition-delay: 0.5s border; */
    width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #c6c6c6;
    padding: 15px;
    overflow: hidden;
    position: relative;
}